import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  calcTax({
    price = 0,
    serviceChargeRate = 0.0,
    taxMode = 'normal',
    taxRoundingMethod = 'round',
    taxRoundingDigit = 0,
    taxRoundingMultiple = 0,
  }) {
    return axiosIns.get(
      '/waiter/checkout_configs/calc_tax',
      {
        params: snakecaseKeys({
          price,
          serviceChargeRate,
          taxMode,
          taxRoundingMethod,
          taxRoundingDigit,
          taxRoundingMultiple,
        }),
      },
    )
  },
  calcTotal({
    price = 0,
    totalRoundingMethod = 'round',
    totalRoundingDigit = 0,
    totalRoundingMultiple = 0,
  }) {
    return axiosIns.get(
      '/waiter/checkout_configs/calc_total',
      {
        params: snakecaseKeys({
          price,
          totalRoundingMethod,
          totalRoundingDigit,
          totalRoundingMultiple,
        }),
      },
    )
  },
  calcProcessingFee({
    price = 0,
    processingFeeRate = 0.0,
    processingFeeTaxMode = 'normal',
    processingFeeRoundingMethod = 'round',
    processingFeeRoundingDigit = 0,
    processingFeeRoundingMultiple = 0,
  }) {
    return axiosIns.get(
      '/waiter/checkout_configs/calc_processing_fee',
      {
        params: snakecaseKeys({
          price,
          processingFeeRate,
          processingFeeTaxMode,
          processingFeeRoundingMethod,
          processingFeeRoundingDigit,
          processingFeeRoundingMultiple,
        }),
      },
    )
  },
  getEnums() {
    return axiosIns.get('/waiter/checkout_configs/enums')
  },
}
